import React from 'react';
import "./annualReport.css";
import delivery from '../../assets/images/delivery.jpg';

function AnnualReport() {
  return (
    <div >
    <section id="air">
    <div className="air_welcome_wrapper container">
      <div className="left text_center">
        <p
        className='heading'
         data-aos="zoom-in"
         data-aos-easing="ease-in"
         data-aos-duration="800"
         data-aos-anchor-placement="top-bottom"
        >
          PERISHABLE CARGO / TEMPERATURE-CONTROLLED CARGO / AIR FREIGHT LOGISTICS</p>
          <div className="main-text">
            <p>1. <span> Temperature Monitoring and Control</span>  Affirm Global Company prioritizes the integrity and freshness of your perishable cargo. Our state-of-the-art facilities and advanced technology enable us to monitor and maintain optimal temperature and humidity conditions throughout transportation. From pharmaceuticals to fresh produce, we ensure your cargo remains within the required temperature range.</p>
            <p>2. <span>Cold Chain Management:</span>   Affirm Global Company has extensive experience managing the cold chain for Temperature-controlled cargo. Our team follows industry best practices and adheres to strict protocols to safeguard the quality and freshness of your shipment. We provide specialized packaging, temperature-controlled containers, and dedicated storage facilities to ensure the integrity of your perishable items.</p>
            <p>3. <span> Compliance with Regulations: </span> Transporting perishable goods involves compliance with various regulations and standards. Our knowledgeable team stays up-to-date with the latest industry requirements, including customs regulations and international standards for cold chain logistics. We handle all necessary permits, documentation, and certifications, ensuring smooth air freight logistics.</p>
            <p>4. <span> Efficient Routing and Expedited Delivery:</span>  Affirm Global Company understands the time-sensitivity of perishable goods. Our logistics experts analyze the most efficient routing options to minimize transit times and maximize shelf life. We work closely with our network of carriers and partners to prioritize the swift and safe delivery of your temperature-sensitive cargo.</p>
            <p>5. <span> Proactive Communication and Tracking:</span>  Affirm Global Company  provides real-time tracking and monitoring of your perishable shipments. Our advanced systems allow you to stay informed about the location and condition of your cargo throughout its journey. In addition, our team offers proactive communication, providing updates and addressing any concerns promptly to ensure peace of mind.</p>
            <span className="heavy">Why Choose Us?</span>
            <ul>
              <li>Specialized expertise in handling perishable and temperature-controlled air cargo</li>
              <li>State-of-the-art facilities and technology for temperature monitoring and control</li>
              <li>Comprehensive cold chain management to maintain cargo integrity</li>
              <li>Compliance with regulations and standards</li>
              <li>Efficient routing and expedited delivery options</li>
              <li>Proactive communication and real-time tracking</li>
            </ul>
            <p>Contact us today to learn more about our Perishable and Temperature-Controlled Air Cargo Services and how we can assist with transporting your sensitive goods. Our dedicated team is ready to provide reliable solutions and exceptional service. Trust Affirm Global Company for all your perishable and temperature-controlled air cargo requirements.</p>
          </div>
        
        </div>
        <div className="right">
          <img src={delivery} alt="" />
        </div>
        </div>
        </section>
    </div>
  )
}

export default AnnualReport