import React, {useEffect} from 'react';
import "./personal.css";
import container from "../../assets/images/shippingcontainers.jpg";


import ScrollToTop from "../../components/scollToTop/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";


function Personal() {
  
  useEffect(() => {
    AOS.init();
  }, []);
  
  return (
    <div>
        <section id="air">
        <div className="air_welcome_wrapper container">
          <div className="left text_center">
            <p
            className='heading'
             data-aos="zoom-in"
             data-aos-easing="ease-in"
             data-aos-duration="800"
             data-aos-anchor-placement="top-bottom"
            >
              HEAVY & OUTSIZE CARGO / LOGISTICS SOLUTIONS / freight forwarding</p>
              <div className="main-text">
                <p>1. <span>Specialized Equipment:</span> Affirm Global Company understands that heavy & outsize cargo requires special handling and equipment. Our team has the tools to safely load and unload your shipment, including cranes, forklifts, and rigging systems. We have experience handling various heavy and outsize items, ensuring secure transportation.</p>
                <p>2.<span>Customized Solutions:</span> Every heavy and outsize cargo is unique, and we tailor our logistics solutions to meet your specific requirements. Our dedicated team works closely with you to understand your cargo's dimensions, weight, and any special considerations. We develop a customized freight forwarding plan that optimizes efficiency and minimizes risks.</p>
                <p>3.<span>Expertise in Regulations: </span>Transporting heavy and outsize cargo involves compliance with various regulations and permits. Our knowledgeable team stays up-to-date with the latest industry regulations and requirements. We ensure that all necessary licenses and paperwork are in order, preventing delays or issues during transportation.</p>
                <p>4.<span>Global Network:</span>  Our extensive partner and carrier network offers comprehensive coverage across significant destinations worldwide. No matter where your heavy and outsize cargo needs to go, we have the resources and partnerships to handle it. We coordinate transportation seamlessly from origin to destination, ensuring a smooth shipping experience.</p>
                <p>5.<span>Safety and Security:</span> The safety and security of your cargo are our top priorities. We take extra precautions to ensure your heavy and outsize load is adequately secured and protected throughout the journey. Our team follows industry best practices and utilizes robust packaging and securing methods to minimize potential risks. </p>
                <span className="heavy">Why Choose Us?</span>
                <ul>
                  <li>Extensive experience in handling heavy and outsize air cargo</li>
                  <li>Specialized equipment and tools for safe loading and unloading</li>
                  <li>Customized solutions tailored to your cargo's unique requirements</li>
                  <li>Compliance with regulations and permits</li>
                  <li>Global network for seamless transportation</li>
                  <li>Commitment to safety and security</li>
                </ul>
                <p>Contact us today to learn more about our Heavy and outsize Air Cargo Services and how we can assist with your transportation needs. Our dedicated team is ready to provide reliable solutions and exceptional service. Trust Perez Safe Logistics for all your heavy and outsize air cargo requirements.</p>
              </div>
            
            </div>
            <div className="right">
              <img src={container} alt="" />
            </div>
            </div>
            </section>
      <ScrollToTop />
    </div>
  )
}

export default Personal