import React, { useState } from 'react';
import "./faq.css";

const faqData = [
  {
    question: 'What services does "Affirm Global offer"?',
    answer: 'Affirm Global provides a comprehensive range of logistics services, including transportation, warehousing, distribution, and supply chain solutions. Our services are tailored to meet the diverse needs of our clients, ensuring safe and efficient handling of goods.',
  },
  {
    question: 'How can I track my shipment with "Affirm Global Company?',
    answer: "We offer a user-friendly online tracking system that allows you to monitor the real-time status of your shipment. Simply visit our website, navigate to the tracking page, and enter your unique tracking number to get instant updates on your cargo's location and estimated delivery time.",
  },
  {
    question: 'What safety measures does Affirm Global Company have in place for transporting goods?',
    answer: 'At Perez Safe Logistics, we prioritize the safety of your goods. Our fleet is equipped with advanced tracking systems, and our drivers undergo regular training on safety protocols. Additionally, we employ secure packaging practices and provide insurance options for added peace of mind.',
  },
  {
    question: ' How do I request a quote for logistics services from Affirm Global Company',
    answer: ' Requesting a quote is easy! Simply visit our website and fill out the online quote form with details about your shipment, such as origin, destination, type of cargo, and any special requirements. Our team will promptly respond with a customized quote tailored to your specific needs.',
  },
  {
    question: 'What regions does "Affirm Global operate in?',
    answer: 'Affirm Global operates on a global scale, serving clients both domestically and internationally. Whether your shipments need to traverse local routes or cross borders, our extensive network and strategic partnerships ensure seamless logistics services across various regions.',
  },
  {
    question: 'How does Affirm Global contribute to sustainability and environmental responsibility?',
    answer: 'We are committed to environmental responsibility. We invest in fuel-efficient vehicles, employ eco-friendly packaging materials, and actively seek ways to minimize our carbon footprint. Our sustainable practices align with our dedication to preserving the environment for future generations.',
  },
  {
    question: 'Can Affirm Global handle specialized or oversized cargo?',
    answer: 'Yes, absolutely!Affirm Global Company specializes in handling a wide range of cargo types, including oversized and specialized shipments. Our team has the expertise and equipment to manage complex logistics requirements, ensuring the safe and secure transportation of your unique cargo.',
  },
];

function Faq() {
  const [openIndex, setOpenIndex] = useState(null);   
  const toggleFAQ = (index) => {
    if (openIndex === index) {
      // If the clicked item is already open, close it
      setOpenIndex(null);
    } else {
      // If the clicked item is closed, open it
      setOpenIndex(index);
    }
  }

return (
    <div>
        <div className="faq-container">
      <h1>Frequently Asked Questions</h1>
      {faqData.map((faq, index) => (
        <div key={index} className="faq-item" onClick={() => toggleFAQ(index)}>
          <button className="faq-question" >
            {faq.question}
          </button>
          {openIndex === index && <p className="faq-answer">{faq.answer}</p>}
        </div>
      ))}
    </div>
    </div>
  )
}

export default Faq