import React from "react";
import "./footer.css";
import logo from "../../assets/images/affirmlogo.jpg";
import phone_icon from "../../assets/images/phoneicon.png";
import location_icon from "../../assets/images/locationicon.png";
import { RiMessage2Fill } from "react-icons/ri";


function Footer() {
  return (
    <div>
      <section id="footer">
        <div className="footer_wrapper container">
          <div className="top">
            <div className="left">
              <img src={logo} alt="Perez Safe Logistics Logo" />
              <p>Safe Deposit Confidention Security And Accredible Shipment.</p>
            </div>
            <div className="middle">
              <ul>
                <li>Menu</li>
                <li><a className="footer_menu" href="/about">About Us</a></li>
                <li><a className="footer_menu" href="/air-freight">Air Freight Fowarding</a></li>
                <li><a className="footer_menu" href="/business">E-Commerce Logistics</a></li>
                <li><a className="footer_menu" href="/e-Commerce">FAQ</a></li>
                <li><a className="footer_menu" href="/contact_us">Contact Us</a></li>
              </ul>
            </div>
            <div className="right">
              <p>GET IN TOUCH</p>
              <div className="contact">
                <img src={location_icon} alt="location" />
                <p>68 Kendell Street Shelsley Walsh,UK</p>
              </div>
              <div className="contact">
                {/* <img src={phone_icon} alt="phone number" />
                <p>+1(917)936-5111 | +447418364964</p> */}
              </div>
              <div className="contact">
                <RiMessage2Fill  className="footer-icon"/>
                <p>management@affirmglobal.co</p>
              </div>
              
              
            </div>
          </div>
          <div className="bottom">
            <div className="divider"></div>

            <div className="main">
            <div className="left">
              <p>Copyright© {new Date().getFullYear()}</p>
            </div>
            <div className="right">
              {/* <a href="/terms&condistions">Terms & Conditions</a>
              <a href="/privacy&policy">Privacy & Plolicy</a> */}
            </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
