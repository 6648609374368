import React from 'react';
import { Outlet } from 'react-router-dom';
import { HeaderTwo } from "../components/Index";

function ProfileLayout() {
  return (
    <div>
        <HeaderTwo />
        <Outlet />
    </div>
  )
}

export default ProfileLayout