import React, {useEffect} from 'react';
import "./business.css";
import CardTwo from "../../components/cardTwo/CardTwo";
import plane from "../../assets/images/plane.jpg";
import ContactSection from '../../components/contactSection/ContactSection';
import ScrollToTop from '../../components/scollToTop/ScrollToTop';

import AOS from "aos";
import "aos/dist/aos.css";

function Business() {

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>

      {/* ======= SECTION : BUSINESS_WELCOME ======= */}
      <section id="air">
        <div className="air_welcome_wrapper container">
          <div className="left text_center">
            <p
            className='heading'
             data-aos="zoom-in"
             data-aos-easing="ease-in"
             data-aos-duration="800"
             data-aos-anchor-placement="top-bottom"
            >
              AIR FREIGHT FORWARDING / LOGISTICS SERVICES</p>
              <div className="main-text">
                <p>1. <span>Customized Solutions:</span> Affirm Global Company understands that every shipment is unique. Our experienced professionals work closely with you to develop tailored solutions that meet your requirements. Whether you need express delivery, oversized cargo handling, or temperature-controlled transportation, we have the expertise to handle it all—logistics solutions (air freight forwarding services)  for the whole World.</p>
                <p>2.<span></span>Global Coverage: As a logistics company, we offer comprehensive coverage across significant destinations worldwide. No matter where your cargo needs to go, we have the resources and partnerships to make it happen. Our extensive network enables us to provide seamless transportation from origin to destination.</p>
                <p>3.<span>Time-Sensitive Delivery:</span> When time is of the essence, you can rely on our expedited air logistics services. We understand the importance of meeting tight deadlines, and our team is dedicated to ensuring that your cargo reaches its destination on time, every time. With our efficient processes and reliable carriers, we minimize transit times and optimize efficiency.</p>
                <p>4.<span>Customs Clearance:</span> Navigating the complexities of customs regulations can be challenging. Our experienced customs brokers handle all the necessary documentation and procedures to ensure smooth customs clearance for your shipments. We stay up-to-date with the latest customs requirements and work diligently to prevent delays or issues.</p>
                <p>5.<span>Tracking and Monitoring:</span> Affirm Global Company provides real-time tracking and monitoring of your air freight shipments. Our advanced tracking systems allow you to stay informed about the progress of your cargo at all times. You can rely on us to update you and proactively communicate throughout shipping.</p>
              </div>
            
            </div>
            <div className="right">
              <img src={plane} alt="" />
            </div>
            </div>
            </section>
            

      {/* ======= SECTION: BUSINESS_GET ======= */}
      <section id="business_get">
       <ContactSection />
      </section>
      <ScrollToTop />
    </div>
  )
}

export default Business