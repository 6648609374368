import React, { useState} from 'react';
import { useNavigate } from 'react-router-dom';
import "./tracking.css";
import ScrollToTop from '../../components/scollToTop/ScrollToTop';
import logo from "../../assets/images/affirmlogo.jpg";



function Tracking({ onCodeEntered }) {
  const [code, setCode] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    // event.preventDefault();
    setCode(event.target.value);
  };

  const handleButtonClick = () => {
  // Check if the entered code is correct (e.g., 'open sesame')
  if (code === 'ZX76DRN938D10LJ3839D430H') {
    // Set state or perform any other actions as needed
    // alert('Tracking number is correct! Redirecting...');
    navigate('/tracking-informationW327787633453568655843'); // Redirect to '/tracking-information'
  } else if(code === 'G78HFH797YGV0808GKJ68') {
    navigate('/tracking-information38SEJS3G4676356DDC0808');
  } else if(code === 'G94N4904JM949M380M440M') {
    navigate('/tracking-information3988J304T4TJ8430J943J043904J9');
  }
};

  return (
    <div>
     
     <section id="register">
        <div className="register_wrapper">
            <div className="top">
                <img src={logo} alt="Affirm Global Company Logo" />
                <h2 className='head text_center'>Logistics Tracking</h2>
                {/* <p className='text_center'>Account Registration</p> */}
            </div>
            <form >
                <input   
                 type="text" 
                 placeholder='Enter tracking number' 
                 value={code} onChange={handleInputChange} 
                 />
                 <button onClick={handleButtonClick} className="register_btn btn">Proceed</button>
            </form>
                 {/* {isCodeCorrect && <SecretSection />} */}
        </div>
    </section>
    <ScrollToTop />

    </div>
  )
}

export default Tracking