import React, { useRef, useState,  useContext } from "react";
// import "./header.css";
import { Link, NavLink } from "react-router-dom";
import navLogo from "../../assets/images/affirmlogo.jpg";
import arrowDown from "../../assets/images/arrowblue.png";
function HeaderTwo() {
    const [toggle, setToggle] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenTwo, setIsOpenTwo] = useState(false);
  const bankRef = useRef();
  const personalRef = useRef();

  window.addEventListener("click", (e) => {
    // console.log(e.target === bankRef.current)
    if (e.target !== bankRef.current && e.target !== personalRef.current) {
      setIsOpen(false);
      setIsOpenTwo(false);
    }
  });
  return (
    <div>
         <nav id="header">
        <div className="header_wrapper container">
          <div 
          onClick={() => setToggle(false)} 
          className="left"
          >
            <Link to="/">
              <img className="header_logo" src={navLogo} alt="header logo" />
            </Link>
          </div>
          <div className="right">
            <ul className={toggle ? "nav_main toggle" : "nav_main"}>
              <li 
              onClick={() => setToggle(!toggle)} 
              className="links"
              >
                <NavLink to="/">Home</NavLink>
              </li>
              <li onClick={() => setToggle(!toggle)}>
                <NavLink to="/about">About Us</NavLink>
              </li>
              <li className="dropdown">
                <span
                  ref={bankRef}
                  onClick={() => setIsOpen(!isOpen)}
                  className="drop_main"
                >
                  Services
                  <div>
                    <img src={arrowDown} alt="" />
                  </div>
                </span>
                <div
                  className={isOpen ? "content_parent show" : "content_parent"}
                >
                  <ul className={isOpen ? "nav_sub slide" : "nav_sub"}>
                    <li>
                      <Link
                      onClick={() => setToggle(!toggle)} 
                       to="/air-freight"
                       >Air Freight Fowarding</Link>
                    </li>
                    <li>
                      <Link 
                      onClick={() => setToggle(!toggle)} 
                       to="/heavy-cargo"
                       >Heavy & Ouside Cargo</Link>
                    </li>
                    <li>
                      <Link 
                      onClick={() => setToggle(!toggle)} 
                       to="/warehousing"
                       >Warehousing</Link>
                    </li>
                    <li>
                      <Link 
                      onClick={() => setToggle(!toggle)} 
                       to="/perishable"
                       >Perishable Cargo</Link>
                    </li>
                    {/* <li>
                      <Link 
                      onClick={() => setToggle(!toggle)} 
                       to="/personal"
                       >Ocean Freight</Link>
                    </li> */}
                    <li>
                      <Link 
                      onClick={() => setToggle(!toggle)} 
                       to="/e-Commerce"
                       >E-Commerce Logistics </Link>
                    </li>
                    
                  </ul>
                </div>
              </li>
              {/* <li onClick={() => setToggle(!toggle)} >
                      <Link to="/tracking">Tracking</Link>
                    </li> */}
              <li onClick={() => setToggle(!toggle)} >
                      <Link to="/faq">Faq</Link>
                    </li>
                    <li onClick={() => setToggle(!toggle)} >
                      <Link to="/contact_us">Contact Us</Link>
                    </li>

            </ul>
          </div>
          <div
            onClick={() => setToggle(!toggle)}
            className={toggle ? "humburger animate" : "humburger"}
          >
            <div className="burger_line"></div>
            <div className="burger_line"></div>
            <div className="burger_line"></div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default HeaderTwo