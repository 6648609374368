import React from 'react';
import warehouse from '../../assets/images/warehouse.jpg'

function Complaint() {
  return (
    <div>
      <section id="air">
        <div className="air_welcome_wrapper container">
          <div className="left text_center">
            <p
            className='heading'
             data-aos="zoom-in"
             data-aos-easing="ease-in"
             data-aos-duration="800"
             data-aos-anchor-placement="top-bottom"
            >
              HEAVY & OUTSIZE CARGO / LOGISTICS SOLUTIONS / freight forwarding</p>
              <div className="main-text">
                <p>1. <span> Secure Storage:</span>  Affirm Global Company warehousing facilities have advanced security systems to provide a safe and secure environment for your air freight cargo. We implement strict access control measures, surveillance cameras, and alarm systems to safeguard your valuable goods. With temperature-controlled options available, we can accommodate interests with specific storage requirements.</p>
                <p>2. <span>Inventory Management:</span>  Affirm Global Company understands the importance of accurate inventory management. Our team utilizes advanced tracking and management systems to keep a detailed record of your cargo. This enables us to provide real-time updates on inventory levels, locations, and movements. You can rely on us to maintain complete visibility and control your air freight shipments.</p>
                <p>3.<span>Order Fulfillment: </span> Affirm Global Company warehousing services go beyond storage. We offer comprehensive order fulfillment solutions to streamline your supply chain. We take the entire process efficiently, from receiving and inspecting incoming goods to picking, packing, handling services, and shipping orders. Our team ensures timely and accurate order fulfillment, helping you meet customer demands effectively.</p>
                <p>4. <span> Value-Added Services:</span>  We provides various value-added services to enhance your air freight warehousing experience. These services may include labeling, kitting, repackaging, and quality inspections. Our flexible approach allows us to tailor these services to your needs, providing added convenience and efficiency.</p>
                <p>5. <span> Distribution and Last-Mile Delivery:</span> Affirm Global Company offers reliable distribution and last-mile delivery solutions as part of our warehousing services. Our extensive network and partnerships enable us to efficiently deliver your air freight shipments to their final destinations. We strive to provide timely and cost-effective distribution, ensuring customer satisfaction.</p>
                <span className="heavy">Why Choose Us?</span>
                <ul>
                  <li>State-of-the-art warehousing facilities with advanced security systems</li>
                  <li>Accurate inventory management and real-time tracking</li>
                  <li>Comprehensive order fulfillment services</li>
                  <li>Flexible value-added services to meet your specific requirements</li>
                  <li>Reliable distribution and last-mile delivery solutions</li>
                  <li>Commitment to customer satisfaction and operational excellence</li>
                </ul>
                <p>Contact us today to learn more about our Air Freight Warehousing Solutions and how we can support your logistics needs. Our dedicated team is ready to provide customized solutions and exceptional service. Trust Affirm Global Company for all your air freight warehousing requirements.</p>
              </div>
            
            </div>
            <div className="right">
              <img src={warehouse} alt="" />
            </div>
            </div>
            </section>
    </div>
  )
}

export default Complaint