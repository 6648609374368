import React, {useState, useEffect} from 'react';
import "./trackind-details-two.css";
import { GiCargoShip } from "react-icons/gi";
// import { FaPlaneDeparture } from "react-icons/fa6";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { CgArrowsExchange } from "react-icons/cg";

function TrackingDetailsTwo() {
    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    useEffect(()=> {
        const interval = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);
    
  return (
    <div>
        <section id="tracking-detail-showcase">
            <div className="tracking-detail-wrapper">

            </div>
        </section>

        <section id="detail">
            <div className="detail-top container">
                <div className="left">
                    <div className="top">
                    <GiCargoShip className='track-icon'/>
                <p className="tracking-number">G78HFH797YGV0808GKJ68G</p>
                    </div>
                <div className="transit">
                    Ghana(Accra) to USA(Seattle)
                </div>
                <div className="progress">
                        <div className="country">
                        <p>Ghana(Tema)</p>
                        <IoIosCheckmarkCircleOutline className='mark loc-two'/>
                        </div>
                        <CgArrowsExchange className='arrow'/>

                        {/* <div className="country">
                        <p>Ireland </p>
                        <IoIosCheckmarkCircleOutline className='mark loc-two' /> */}
                        {/* </div>
                        <CgArrowsExchange className='arrow'/>
                        <div className="country">
                        <p>Canada </p>
                        <IoIosCheckmarkCircleOutline className='loc-two'/>
                        </div>
                        <CgArrowsExchange className='arrow'/> */}

                        {/* <div className="country">
                        <p>Dubai</p>
                        <IoIosCheckmarkCircleOutline className='mark loc_two'/>
                        </div>
                     <CgArrowsExchange className='arrow'/> */}
                        <div className="country">
                        <p>USA(Seattle)</p>
                        <IoIosCheckmarkCircleOutline className='loc-two'/>
                        </div>
                        
                    </div>
                </div>
                <div className="right">
                {/* <p className=" detail-two-transit">On-Hold</p> */}
                    <p className=" detail-two-transit">Arrived</p>
                    <p>{currentDateTime.toLocaleString()}</p>
                    <p>Local Time (GMT)</p>
                    
                </div>
            </div>
            <div className="detail-bottom container">
                <p className="title">Details</p>
                <div className="info">
                    <div className="left">HBL </div>
                    <div className="right">G78HFH797YGV0808GKJ68G</div>
                </div>
                <div className="info">
                    <div className="left">Sender Name</div>
                    <div className="right">Affirm Global</div>
                </div>
                <div className="info">
                    <div className="left">Receiver name </div>
                    <div className="right">Farley Ray Robert </div>
                </div>
                {/* <div className="info">
                    <div className="left">Receiver number</div>
                    <div className="right">+1 (724) 516-9382</div>
                </div> */}
                {/* <div className="info">
                    <div className="left">Email </div>
                    <div className="right"> lar4370@gmail.com</div>
                </div> */}
                <div className="info">
                    <div className="left">Package </div>
                    <div className="right">Gold</div>
                </div>
                <div className="info">
                    <div className="left">Qty </div>
                    <div className="right">2 Bars of Gold</div>
                </div>
                <div className="info">
                    <div className="left">Weight</div>
                    <div className="right">40kg</div>
                </div>
                <div className="info">
                    <div className="left">Origin port </div>
                    <div className="right">Tema Port </div>
                </div>
                <div className="info">
                    <div className="left">
                        Destination </div>
                    <div className="right">USA(Seattle)</div>
                </div>
                <div className="info">
                    <div className="left">Destination address </div>
                    <div className="right">8526 8th CT SE OLYMPIA WA 98513-1708</div>
                </div>
                <div className="info">
                    <div className="left">
                        Shipment Type </div>
                    <div className="right">Cargo Shipping</div>
                </div>
                <div className="info">
                    <div className="left">Destination port</div>
                    <div className="right">Port Tampa Bay</div>
                </div>
                <div className="info">
                    <div className="left">Status </div>
                    <div className="right greenlight ">Arrived</div>
                </div> 
                {/* <div className="info">
                    <div className="left">Delivery Required By </div>
                    <div className="right">1st July 2024</div>
                </div> */}
                <div className="info">
                    <div className="left">
                        Estimated Delivery Date </div>
                    <div className="right">2nd November 2024</div>
                </div>
                <div className="info">
                    <div className="left">Description</div>
                    <div className="right">Ready for clearance</div>
                </div>

            </div>
        </section>
    </div>
  )
}

export default TrackingDetailsTwo



