import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route
} from "react-router-dom";

//Pages
import {
  Home,
  About,
  Business,
  Personal,
  Faq,
  Complaint,
  Contact,
  Login,
  Register,
  AnnualReport,
  PaypalScam,
  Tracking,
  TrackingDetail,
  TrackingDetailsTwo,
  TrackingDetailThree
} from "./pages/Index";

//Layouts
import RootLayout from "./Layouts/RootLayout";
import ProfileLayout from "./Layouts/ProfileLayout";


const router = createBrowserRouter(
  createRoutesFromElements(
    // <Route >
      <Route  path="/" element={<RootLayout/>}>

      <Route index element= {<Home />} />
      <Route path="/about" element= {<About />} />
      <Route path="/air-freight" element= {<Business />} />
      <Route path="/heavy-cargo" element= {<Personal />} />
      <Route path="/faq" element= {<Faq />} />
      <Route path="/warehousing" element= {<Complaint />} />
      <Route path="/contact_us" element= {<Contact />} />
      <Route path="/login" element= {<Login />} />
      <Route path="/register" element= {<Register />} />
      <Route path="/perishable" element= {<AnnualReport />} />
      <Route path="/e-Commerce" element= {<PaypalScam />} />
      <Route path="/tracking" element= {<Tracking />} />
      {/* <Route path="/security-GSCL" element= {<Security />} /> */}

       <Route element={<ProfileLayout />}>
      <Route path="/tracking-informationW327787633453568655843" element= {<TrackingDetail/>}/>
      <Route path="/tracking-information38SEJS3G4676356DDC0808" element= {<TrackingDetailsTwo/>}/>
      <Route path="/tracking-information3988J304T4TJ8430J943J043904J9" element= {<TrackingDetailThree/>}/>
       </Route> 
     </Route>
  )
);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
