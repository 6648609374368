import React, {useState, useEffect} from 'react';
import './trackingdetailthree.css';
import { FaPlaneDeparture } from "react-icons/fa6";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { CgArrowsExchange } from "react-icons/cg";

function TrackingDetailThree() {
    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    useEffect(()=> {
        const interval = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

  return (
     <div>
        <section id="tracking-detail-showcase">
            <div className="tracking-detail-wrapper">

            </div>
        </section>

        <section id="detail">
            <div className="detail-top container">
                <div className="left">
                    <div className="top">
                    <FaPlaneDeparture className='track-icon'/>
                <p className="tracking-number">G94N4904JM949M380M440M</p>
                    </div>
                <div className="transit">
               DR Congo to USA(WV)
                </div>
                <div className="progress progress-three">
                        <div className="country">
                        <p>DRC</p>
                        <IoIosCheckmarkCircleOutline className='mark loc-three'/>
                        </div>
                        <CgArrowsExchange className='arrow'/>

                        <div className="country">
                        <p>Belgium</p>
                        <IoIosCheckmarkCircleOutline className='mark loc-three' />
                        </div>
                        <CgArrowsExchange className='arrow'/> 
                     <div className="country">
                        <p>Netherlands</p>
                        <IoIosCheckmarkCircleOutline className='mark loc-three'/>
                        </div>
                        {/* <CgArrowsExchange className='arrow'/>

                        <div className="country">
                        <p>Thailand</p>
                        <IoIosCheckmarkCircleOutline className='mark'/>
                        </div> */}
                        {/* <CgArrowsExchange className='arrow'/> */}
                        <CgArrowsExchange className='arrow'/>
                        {/* <div className="country">
                        <p>Australia</p>
                        <IoIosCheckmarkCircleOutline className='mark'/>
                        </div> */}
                        
                    <div className="country">
                        <p>USA(WV)</p>
                        <IoIosCheckmarkCircleOutline className='mark loc-three'/>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <p className="detail-three-transit  redlight">On-Hold</p>
                    <p>{currentDateTime.toLocaleString()}</p>
                    <p>Local Time (GMT)</p>
                    
                </div>
            </div>
            <div className="detail-bottom container">
                <p className="title">Details</p>
                <div className="info">
                    <div className="left">HBL </div>
                    <div className="right">ZX76DRN938D10LJ3839D430H</div>
                </div>
                <div className="info">
                    <div className="left">Sender Name</div>
                    <div className="right">Affirm Global</div>
                </div>
                <div className="info">
                    <div className="left">Receiver name </div>
                    <div className="right">Donald L Owen’s</div>
                </div>
                {/* <div className="info">
                    <div className="left">Receiver number</div>
                    <div className="right"></div>
                </div> */}
                <div className="info">
                    <div className="left">Email </div>
                    <div className="right">donowens80@icloud.com</div>
                </div>
                <div className="info">
                    <div className="left">Package </div>
                    <div className="right">45.6Kg of Gold</div>
                </div>
                {/* <div className="info">
                    <div className="left">Qty </div>
                    <div className="right">2 pieces</div>
                </div> */}
                {/* <div className="info">
                    <div className="left">Weight</div>
                    <div className="right">2,000 grams</div>
                </div> */}
                <div className="info">
                    <div className="left">Origin port </div>
                    <div className="right">N'djili Airport(Kinshasa)</div>
                </div>
                <div className="info">
                    <div className="left">
                        Destination </div>
                    <div className="right">Democratic Republic of Congo</div>
                </div>
                <div className="info">
                    <div className="left">Destination address </div>
                    <div className="right">West Virginia/St. Albans 25177. 2733 Bard Avenue</div>
                </div>
                <div className="info">
                    <div className="left">
                        Shipment Type </div>
                    <div className="right">Air Freight</div>
                </div>
                <div className="info">
                    <div className="left">Destination port</div>
                    <div className="right">Yeager Airport(CRW)</div>
                </div>
                <div className="info">
                    <div className="left">Status </div>
                    <div className="right greenlight">
                        {/* In-Transit */}
                        <span className='redlight'>( On-Hold )</span> 
                        </div>
                </div> 
                <div className="info">
                    <div className="left">Delivery Required By </div>
                    <div className="right">15<sup>th</sup>&ensp;December, 202</div>
                </div>
                <div className="info">
                    <div className="left">
                        Estimated Delivery Date </div>
                    <div className="right">16<sup>th</sup>&ensp;December, 2024 </div>
                </div>
                <div className="info">
                    <div className="left">Description</div>
                    <div className="right redlight ">Inappropriate insurance valuation</div>
                </div>

            </div>
        </section>
    </div>
  )
}

export default TrackingDetailThree