import React from 'react';
import "./paypalScam.css";
import delivery from '../../assets/images/delivery.jpg';

function PaypalScam() {
    

  return (
    <div>
         <section id="air">
    <div className="air_welcome_wrapper container">
      <div className="left text_center">
        <p
        className='heading'
         data-aos="zoom-in"
         data-aos-easing="ease-in"
         data-aos-duration="800"
         data-aos-anchor-placement="top-bottom"
        >
          
          LOGISTICS for E-COMMERCE AND MAIL FOWARDING</p>
          <div className="main-text">
            <p className="heavy">Alliance Cargo Express is the largest service provider for e-commerce businesses.</p>
            <p>If you are an international e-commerce business and need to ship your goods worldwide, you may want to consider using air freight services. Perez Safe Logistics is a company that organizes and manages the transportation of your goods by air from origin to destination. ACE can help you save time, money, and stress while dealing with logistics, customs, and documentation of your shipments.</p><br/>
            <p>There are many benefits to using air freight forwarding services for your international e-commerce or mail-forwarding business, such as:</p><br/>
            <p>1. <span> Speed:</span> Air freight is the fastest mode of transport in international trade. You can deliver your products to your customers in days rather than weeks or months. This can improve customer satisfaction and retention, as well as improve cash flow and inventory management.</p>
            <p>2. <span>Flexibility: </span>   Air freight lets you transport a wide range of goods, from small parcels to oversized pallets. You can also choose different service levels and options depending on your needs and budget. For example, you can select a door-to-port service, including pickup, delivery, and customs clearance, or a port-to-port service covering only transport between airports.</p>
            <p>3. <span>Security: </span> Air freight is subject to strict security regulations and controls to ensure the safety and integrity of your goods. You may also take advantage of insurance coverage and liability protection offered by air travel services or airlines.</p>
            <p>4. <span> Regulations:</span>  Air travel is subject to complex and varied regulations and requirements from different countries and authorities. You must comply with rules and standards for packaging, labeling, documentation, certification, and inspection of your goods. You should also be aware of restrictions and prohibitions on specific products or materials that are not permitted or require special air transportation permits.</p>
            
            <span className="heavy">Why Choose Us?</span>
            
            <p>To overcome these challenges and limitations, you must partner with a reliable and trusted air freight forwarding company that can provide you with quality and reliable services at competitive prices. e-commerce logistics / international shipping / international parcel</p>
          </div>
        
        </div>
        <div className="right">
        <img src={delivery} alt="" />
        </div>
        </div>
        </section>
    </div>
  )
}

export default PaypalScam