import { useEffect} from "react";
import "./home.css";
import { Link} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
// import { userContext } from "../../context/userContext";

// images
import slideImage1 from "../../assets/images/plane.jpg";
import slideImage2 from "../../assets/images/businessteam.jpg";
import slideImage3 from "../../assets/images/port.jpg";
import slideImage4 from "../../assets/images/truck.jpg";
import complaintImg from "../../assets/images/question.png";
import businessImg from "../../assets/images/homeiconwhite.png";
import personalImg from "../../assets/images/bankingiconwhite.png";
import supportImg from "../../assets/images/settings.png";
import budgetImg from "../../assets/images/budget.png";
import cardImg1 from "../../assets/images/cargoship.jpg";
import bankIcon from "../../assets/images/bankingicon.png";
import homeIcon from "../../assets/images/homeicon.png";
import chartIcon from "../../assets/images/chart.png";
import tombIcon from "../../assets/images/tomb.png";
import fundsIcon from "../../assets/images/funds.png";
import loanCardImage from "../../assets/images/loancard.png";
import annualReport from "../../assets/images/warehouse.jpg";
import scamAlert from "../../assets/images/shippingcontainers.jpg";

import SquareCard from "../../components/squareCard/SquareCard";
import CardTwo from "../../components/cardTwo/CardTwo";
import ScrollToTop from "../../components/scollToTop/ScrollToTop";


function Home() {
  // const [username, setUsername] = useState("");
  // const [password, setPassword] = useState("");
  // const [redirect, setRedirect] = useState(false);
  // const {setUserInfo} = useContext(userContext);

  useEffect(() => {
    AOS.init();
  }, []);

  // async function login(e) {
  //   e.preventDefault();
  //  const response = await fetch('https://slick-union-bank.onrender.com/login', {
  //     method: "POST",
  //     body: JSON.stringify({username,password}),
  //     headers: {"Content-Type": "application/json"},
  //     credentials: "include",
  //   });
  //   if (response.ok) {
  //     response.json().then(userInfo => {
  //       setUserInfo(userInfo);
  //       setRedirect(true);
  //     })
  //   } else {
  //     alert("wrong credentials");
  //   }
  // }

  // if (redirect) {
  //   return <Navigate to={"/profile"} />
  // }

  return (
    <div>
      <section id="home">
        <div className="home_showcase_wrapper">
          <div className="home_swiper_background">
            <Swiper
              // navigation={true}
              centeredSlides={true}
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
              modules={[ Autoplay]}
              className="mySwiper"
              slidesPerView={1}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
            >
              <SwiperSlide>
                <div
                data-aos="fade-up"
                data-aos-easing="ease-in"
                data-aos-duration="500"
                className="left">
                  <p>Air Freight Forwarding</p>
                  <p className="text">The process starts by arranging for the pickup of goods from the shipper's location and then transporting them to the airport for shipment.</p>
                  <Link className="btn_main" to="/air-freight">Learn more</Link>
                  </div>
                <div>
          <div className="blue_overlay"></div>

                  <img className="first_slide_image" src={slideImage1} alt="side_image" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
              <div
                className="left">
                  <p>Your Most Trusted Partner </p>
                  <p className="text">We give you the best and most reliable solutions and we have the truck record.</p>
                  <Link className="btn_main" to="/tracking">Track Shipment</Link>
                  </div>
                  <div>
          <div className="blue_overlay"></div>
                <img src={slideImage2} alt="side_image" />
                  </div>
              </SwiperSlide>
              <SwiperSlide>
              <div
                className="left">
                  <p>Ocean Freight</p>
                  <p className="text">We have well experiences stuff who are dedicated to you and your comfort... Talk to us now! <br/><br/> </p>
                  <Link className="btn_main" to="/contact_us">Contact Us</Link>
                  </div>
                  <div>
          <div className="blue_overlay"></div>
                <img src={slideImage3} alt="side_image" />
                  </div>
              </SwiperSlide>
              <SwiperSlide>
              <div
               
                className="left">
                  <p>E-COMMERCE LOGISTICS</p>
                  <p className="text">E-commerce is a fast-growing industry that requires fast and reliable delivery for international mail-forwarding companies worldwide.</p>
                  <Link className="btn_main" to="/contact_us">Contact Us</Link>
                  </div>
                  <div>
          <div className="blue_overlay"></div>
                <img src={slideImage4} alt="side_image" />
                  </div>
              </SwiperSlide>
            </Swiper>
          </div>

          {/* <form onSubmit={login} className="home_form">
            <p>Account Login</p>
            <label>User ID</label>
            <input 
            type="text" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} />

            <label>Password</label>
            <input 
            type="password" 
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            />
            <Link to="/">Forgot Password?</Link>
            <button className="home_form_button btn">Login</button>
          </form> */}
        </div>
      </section>


                        {/* SECTION: HOME_BOXES */}
      <section id="home_boxes">
        <div className="home_boxes_wrapper container">
          <div className="card_wrapper">
          <SquareCard 
          title= "Time-Sensitive Delivery"
          src = {businessImg}
          alt = "Perez Safe Logistics"
          />
          </div>

          <div className="card_wrapper">
          <SquareCard
          title= "Specialized Handling"
          src = {personalImg}
          alt = "Perez Safe Logistics"
          />
          </div>

          <div className="card_wrapper">
          <SquareCard
          title= "Global Coverage"
          src = {supportImg}
          alt = "Perez Safe Logistics"
          />
          </div>

          <div className="card_wrapper">
          <SquareCard
          title= "Customs Clearance"
          src = {complaintImg}
          alt = "Perez Safe Logistics "
          />
          </div>
      
        </div>
      </section>

                             {/* SECTION : HOME_HELP */}
<section id="home_help">
  <div className="home_help_wrapper container">
    <div className="top text_center">
      <img src= {budgetImg} alt="" />
      <p>Let’s Achieve  your Logistic stability together.</p>
      <p>We can help <div className="line"></div></p>
      <p>Perez Safe Logistics extensive carrier network covers significant destinations worldwide. Whether you need to ship to Asia, Europe, Africa, or any other part of the globe, we have the capabilities and connections to get your cargo there —Logistics Solutions for the whole World.</p>
    </div>

    <div className="bottom">
      <div 
       data-aos="fade-up"
       data-aos-easing="ease-in"
       data-aos-duration="700"
      className="card"
      >
        <img src={annualReport} alt="" />
        <div className="text">
          <p>Warehousing</p>
          <p> In the air freight forwarding industry is a service that involves storing, handling, and distributing goods that are transported by air from one place to another.</p>
          <a className="btn_2 text_center" href="/warehousing">Learn More</a>
        </div>
      </div>
      <div 
      data-aos="fade-up"
      data-aos-easing="ease-in"
      data-aos-duration="900"
      className="card"
      >
        <img src= {scamAlert} alt="" />
        <div className="text">
          <p>PERISHABLE CARGO</p>
          <p> Any cargo that is time or temperature-sensitive and requires fast and safe delivery to maintain its quality and effectiveness.</p>
          <a className="btn_2 text_center" href="/perishable">Learn More</a>
        </div>
      </div>
      <div 
      data-aos="fade-up"
      data-aos-easing="ease-in"
      data-aos-duration="1100"
      className="card"
      >
        <img src={cardImg1} alt="" />
        <div className="text">
          <p>HEAVY & OUTSIZE CARGO</p>
          <p> This refers to any cargo that exceeds the standard dimensions and weight limits for air, sea, or land transportation.</p>
          <a className="btn_2 text_center" href="/about">Learn More</a>
        </div>
      </div>
    </div>
  </div>
</section>

{/* ======= SECTION:PARTNERING ======= */}
<section id="partnering">
  <div className="partnering_wrapper">
    <div className="top text_center container">
      <p className="head">SUPPLY CHAIN SOLUTIONS</p>
      <p className="head">We have the experience</p>
      <div className="line"></div>
      <p>They enable organizations to optimize their supply chain processes and adapt to evolving customer demands and global economic conditions.</p>
    </div>

    <div className="bottom">
      <div className="card_box">
      <div className="home_business">
        <p 
        data-aos="zoom-in"
        data-aos-easing="ease-in"
        data-aos-duration="800"
        className="partnering_title"
        >
          Logistical Services
          </p>
        <div className="partnering_cards">
          <div
          data-aos="fade-up"
          data-aos-easing="ease-in"
          data-aos-duration="800"
          >
          <CardTwo
          src={bankIcon}
          alt="Perez Safe Logistics icon"
          title="AOG - AIRCRAFT ON GROUND"
          text="These services can provide fast, reliable, and cost-effective solutions for AOG situations."
          />
          </div>
          <div
          data-aos="fade-up"
          data-aos-easing="ease-in"
          data-aos-duration="800"
          >
          <CardTwo
          src={bankIcon}
          alt="Affirm Global Company icon"
          title="TRUCKING SOLUTIONS"
          text="Affirm Global Company Trucking Solutions are services that help customers transport goods by truck from one place to another."
          />
          </div>

          <div
          data-aos="fade-up"
          data-aos-easing="ease-in"
          data-aos-duration="800"
          >
          <CardTwo
          src={bankIcon}
          alt="Affirm Global Company icon"
          title="CUSTOMS CLEARANCE"
          text=" This process involves the submission and approval of documents and payments related to the import or export of goods across international borders."
          />
          </div>
          <div
          data-aos="fade-up"
          data-aos-easing="ease-in"
          data-aos-duration="800"
          >
          <CardTwo
          src={bankIcon}
          alt="Affirm Global Company icon"
          title="DANGEROUS GOODS"
          text="Awareness of dangerous goods by air are items or substances that may pose a risk to the health, safety, or property of the aircraft, crew, passengers, or environment when transported by air."
          />
          </div>
        </div>
      </div>
      </div>

      <div className="home_personal">
        <div className="card_box">
      <div className="home_business">
        <p 
        className="partnering_title"
        data-aos="zoom-in"
          data-aos-easing="ease-in"
          data-aos-duration="800"
        >
          More Services
          </p>
        <div className="partnering_cards">
          <div
          data-aos="fade-up"
          data-aos-easing="ease-in"
          data-aos-duration="800"
          >
          <CardTwo
          src={homeIcon}
          alt=" icon"
          title="TRANSPORTATION OF LUXURY VEHICLES"
          text=" Transportation of Luxury Vehicles by air is a service that we provide at Affirm Global Logistics"
          />
          </div>
          <div
          data-aos="fade-up"
          data-aos-easing="ease-in"
          data-aos-duration="800"
          >
          <CardTwo
          src={homeIcon}
          alt="Perez Safe Logistics icon"
          title="FINE ARTS and TRADE SHOWS."
          text=" You have come to the right place if you are looking for a reliable and professional company to handle your fine art shipping needs"
          />
          </div>
          <div
          data-aos="fade-up"
          data-aos-easing="ease-in"
          data-aos-duration="800"
          >
          <CardTwo
          src={homeIcon}
          alt="Perez Safe Logistics icon"
          title="CUSTOMS CLEARANCE"
          text="Submission and approval of documents and payments related to the import or export of goods across international borders. "
          />
          </div>
          <div
          data-aos="fade-up"
          data-aos-easing="ease-in"
          data-aos-duration="800"
          >
          <CardTwo
          src={homeIcon}
          alt="Perez Safe Logistics icon"
          title="Air Cargo Handling"
          text="Air Cargo Handling facility to processed and prepared for air transportation. It is an essential part of the air cargo supply chain, ensuring that the goods are delivered safely, securely, and efficiently to their destination. "
          />
          </div>
        </div>
      </div>
      </div>
      </div>
    </div>
  </div>
</section>


{/* ======= SECTION : LOAN ======= */}
<section id="home_loan">
  <div className="home_loan_wrapper container">
    <div className="top">
      <p className="heading">Our Logistics Service Provision</p>
<div className="line_two"></div>
      <p>Here are three main steps in the logistics service provision process:</p>
    </div>

    <div className="bottom">
    <div className="left">
      <div className="process">
        <div className="icon">
          <div
          data-aos="zoom-up"
          data-aos-easing="ease-in"
          data-aos-duration="600"
          data-aos-anchor-placement="top-bottom"
          >
          <img src={chartIcon} alt="" />
          </div>
          <div 
          data-aos="zoom-up"
          data-aos-easing="ease-in"
          data-aos-duration="600"
          data-aos-anchor-placement="top-bottom"
          className="rod"
          ></div>
        </div>
        <div className="description">
          <p 
          data-aos="fade-up"
          data-aos-easing="ease-in"
          data-aos-duration="700" 
          data-aos-anchor-placement="top-bottom"
          >Planning and Coordination</p>
          <p
          data-aos="fade-up"
          data-aos-easing="ease-in"
          data-aos-duration="900"
          data-aos-anchor-placement="top-bottom"
          >The first step in logistics service provision is thorough planning and understanding the client's requirements.</p>
        </div>
      </div>
      <div className="process">
        <div className="icon">
          <div
          data-aos="zoom-up"
          data-aos-easing="ease-in"
          data-aos-duration="600"
          >
          <img src={tombIcon} alt="" />
          </div>
          <div
          data-aos="zoom-up"
          data-aos-easing="ease-in"
          data-aos-duration="600"
          className="rod_two"
          ></div>
        </div>
        <div className="description">
          <p
          data-aos="fade-up"
          data-aos-easing="ease-in"
          data-aos-duration="700"
          data-aos-anchor-placement="top-bottom"
          >Execution and Transportation</p>
          <p
          data-aos="fade-up"
          data-aos-easing="ease-in"
          data-aos-duration="900"
          data-aos-anchor-placement="top-bottom"
          >Once the logistics plan is in place, the next step is the execution of the plan.</p>
        </div>
      </div>
      <div className="process">
        <div className="icon">
          <div 
          data-aos="zoom-up"
          data-aos-easing="ease-in"
          data-aos-duration="700"
          data-aos-anchor-placement="top-bottom"
          >
          <img src={fundsIcon} alt="" />
          </div>
        </div>
        <div className="description">
          <p
          data-aos="fade-up"
          data-aos-easing="ease-in"
          data-aos-duration="700"
          data-aos-anchor-placement="top-bottom"
          >Warehousing and Distribution</p>
          <p
          data-aos="fade-up"
          data-aos-easing="ease-in"
          data-aos-duration="900"
          data-aos-anchor-placement="top-bottom"
          >Efficient warehousing ensures that products are readily available for shipping and minimizes lead times in the supply chain.</p>
        </div>
      </div>
    </div>
    <div
     data-aos="fade-up"
     data-aos-easing="ease-in"
     data-aos-duration="700"
     data-aos-anchor-placement="top-center"
    className="right">
      <img src={loanCardImage} alt="" />
    </div>
    </div>
  </div>
</section>
<ScrollToTop />
    </div>
  );
}

export default Home;
