import React,{useState} from 'react';
// import logo from "../../assets/images/only_logo.png";
import "./register.css";
import ScrollToTop from '../../components/scollToTop/ScrollToTop';

function Register() {
    const [username, setUsername] =useState ("");
    // const [password, setPassword] = useState("");

    async function registrationForm(e) {
      e.preventDefault();
  
    //   try {
          await fetch('http://localhost:4000', {
              method: 'POST',
              body: JSON.stringify({ username}),
              headers: {
                  'Content-Type': 'application/json',
              },
          });
  
    //       if (response.ok) {
    //           alert('Registration successful');
    //       } else {
    //           alert('Registration failed');
    //       }
    //   } catch (error) {
    //       console.error('Error during registration:', error);
    //       alert('Registration failed');
    //   }
  }


  return (
    <>
    <section id="register">
        <div className="register_wrapper">
            <div className="top">
                {/* <img src={logo} alt="peres safe logistics Logo" /> */}
                {/* <h2 className='head text_center'>SLICK UNION BANK</h2> */}
                <p className='text_center'>Account Registration</p>
            </div>
            <form onSubmit={registrationForm}>
                <input 
                 type="text" 
                 placeholder='Username' 
                 value={username}
                 onChange={ e => setUsername(e.target.value)} 
                 />
                 <button className="register_btn btn">register</button>
            </form>
        </div>
    </section> 
    <ScrollToTop />
    </>
  )
}

export default Register