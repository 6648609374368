import React, {useState, useEffect} from 'react';
import "./trackingdetail.css";
import { FaPlaneDeparture } from "react-icons/fa6";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { CgArrowsExchange } from "react-icons/cg";



function TrackingDetail() {
    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    useEffect(()=> {
        const interval = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);
    
  return (
    <div>
        <section id="tracking-detail-showcase">
            <div className="tracking-detail-wrapper">

            </div>
        </section>

        <section id="detail">
            <div className="detail-top container">
                <div className="left">
                    <div className="top">
                    <FaPlaneDeparture className='track-icon'/>
                <p className="tracking-number">EX7DN938D10LJ3839D430H</p>
                    </div>
                <div className="transit">
                United Kingdom(UK) to Australia(AU)
                </div>
                <div className="progress">
                        <div className="country">
                        <p>UK</p>
                        <IoIosCheckmarkCircleOutline className='mark'/>
                        </div>
                        <CgArrowsExchange className='arrow'/>

                        <div className="country">
                        <p>Belgium</p>
                        <IoIosCheckmarkCircleOutline className='mark' />
                        </div>
                        <CgArrowsExchange className='arrow'/> 
                     <div className="country">
                        <p>UAE</p>
                        <IoIosCheckmarkCircleOutline className='mark'/>
                        </div>
                        <CgArrowsExchange className='arrow'/>

                        <div className="country">
                        <p>Thailand</p>
                        <IoIosCheckmarkCircleOutline className='mark'/>
                        </div>
                        <CgArrowsExchange className='arrow'/>
                        {/* <CgArrowsExchange className='arrow'/> */}
                        {/* <div className="country">
                        <p>Australia</p>
                        <IoIosCheckmarkCircleOutline className='mark'/>
                        </div> */}
                        
                    </div>
                    <div className="country">
                        <p>Australia</p>
                        <IoIosCheckmarkCircleOutline className='mark'/>
                        </div>
                </div>
                <div className="right">
                    <p className=" greenlight">Arrived</p>
                    <p>{currentDateTime.toLocaleString()}</p>
                    <p>Local Time (GMT)</p>
                    
                </div>
            </div>
            <div className="detail-bottom container">
                <p className="title">Details</p>
                <div className="info">
                    <div className="left">HBL </div>
                    <div className="right">ZX76DRN938D10LJ3839D430H</div>
                </div>
                <div className="info">
                    <div className="left">Sender Name</div>
                    <div className="right">Affirm Global</div>
                </div>
                <div className="info">
                    <div className="left">Receiver name </div>
                    <div className="right">Beau and Christian Jones</div>
                </div>
                {/* <div className="info">
                    <div className="left">Receiver number</div>
                    <div className="right"></div>
                </div> */}
                <div className="info">
                    <div className="left">Email </div>
                    <div className="right">rrosathompson6@gmail.com</div>
                </div>
                <div className="info">
                    <div className="left">Package </div>
                    <div className="right">100Kg of Gold</div>
                </div>
                {/* <div className="info">
                    <div className="left">Qty </div>
                    <div className="right">2 pieces</div>
                </div> */}
                {/* <div className="info">
                    <div className="left">Weight</div>
                    <div className="right">2,000 grams</div>
                </div> */}
                <div className="info">
                    <div className="left">Origin port </div>
                    <div className="right">Edinburgh Airport (EDI)</div>
                </div>
                <div className="info">
                    <div className="left">
                        Destination </div>
                    <div className="right">Australia</div>
                </div>
                <div className="info">
                    <div className="left">Destination address </div>
                    <div className="right">SE battery's 120 Penola Rd Mt Gambier is the address</div>
                </div>
                <div className="info">
                    <div className="left">
                        Shipment Type </div>
                    <div className="right">Air Freight</div>
                </div>
                <div className="info">
                    <div className="left">Destination port</div>
                    <div className="right">Hamilton Island Airport</div>
                </div>
                <div className="info">
                    <div className="left">Status </div>
                    <div className="right greenlight">Arrived
                        {/* <span className='redlight'>(Pending approval)</span>  */}
                        </div>
                </div> 
                {/* <div className="info">
                    <div className="left">Delivery Required By </div>
                    <div className="right"></div>
                </div> */}
                <div className="info">
                    <div className="left">
                        Estimated Delivery Date </div>
                    <div className="right">12<sup>th</sup>&ensp;August, 2024 </div>
                </div>
                <div className="info">
                    <div className="left">Description</div>
                    <div className="right redlight">Ready for clearance</div>
                </div>

            </div>
        </section>
    </div>
  )
}

export default TrackingDetail